
<template>
	<div>
		<h2>{{ $t('client_area.title') }}</h2>
		<div class="box">
			<b-button @click="triggerAutoLog">
				{{ $t('client_area.autolog_tier') }} {{ $cache.tiers.tiers_rs }}
			</b-button>
		</div>

		<ModalConfirm
			ref="confirm_autolog"
			id="confirm_autolog"
			:icon="['fal', 'check']"
			:text_title="$t('client_area.autolog_modal_title')"
			:text_question="$t('client_area.autolog_modal_body')"
			:text_button_ok="$t('client_area.autolog_modal_confirm')"
			:text_button_cancel="$t('modal.general.annuler')"
			:callback_validation="askAutoLog"
			:callback_cancel="cancelAutoLog"
		/>
	</div>
</template>

<script type="text/javascript">
import User from "@/mixins/User.js"

export default {
    name: 'AutoLogCa',
	mixins: [User],

	methods: {
		triggerAutoLog() {
			this.$refs.confirm_autolog.openModal()
		},
		cancelAutoLog() {
			this.$refs.confirm_autolog.closeModal()
		},
		async askAutoLog() {
			const url = await this.autologCaTier(this.$cache.tiers.tiers_mail)
			const win = window.open(url, '_blank')
  			win.focus()
		}
    },

    components: {
		ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
    }
}
</script>